import { BasicHero, ErrorMsg, GlobalBlocks, Header, HeroSlider, SEO } from "@shared";
import { PageProps, graphql } from "gatsby";

import { Query } from "@graphql-types";
import React from "react";
import { usePageMeta } from "@util/logicHooks";
import VariationSelect from "@components/variationSelect";
import { FramePopup } from "@global";

interface Props extends PageProps {
  data: Query;
}

export default function PagesTemplate({
  data: { sanityPage, sanityHeader, allSanityForm },
}: Props) {
  if (sanityPage == null) return <ErrorMsg data={sanityPage} msg="Error fetching data for page" />;

  const {
    slug,
    seo,
    title,
    heroSlider,
    pageColour,
    _type,
    blocks,
    variationReference,
    variationThemeColor,
    careNavigation,
    popup,
  } = sanityPage;

  const isHomePage = slug?.current === "/";

  usePageMeta(pageColour?.colour?.hex, _type);

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} isHomePage={isHomePage} />
      {popup && <FramePopup data={popup} />}
      {Number(heroSlider?.slides?.length) > 0 ? (
        <HeroSlider
          data={heroSlider}
          careNavigation={careNavigation}
          variationSelector={variationReference}
        />
      ) : (
        <BasicHero heading={title} />
      )}
      <VariationSelect data={variationReference} color={variationThemeColor} location="fold" />

      <GlobalBlocks data={blocks} guides={allSanityForm.nodes} />
    </div>
  );
}

export const query = graphql`
  query pagesQuery($slug: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityPage(slug: { current: { eq: $slug } }) {
      ...sanityPage
    }
    allSanityForm(sort: { order: ASC, fields: order }, filter: { formType: { eq: "guide" } }) {
      nodes {
        ...sanityFormPreview
      }
    }
  }
`;
